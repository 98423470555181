import config from 'next/config';

export const getBackendUrl = (route) => {
  const {
    publicRuntimeConfig: { BACKEND_URL },
    serverRuntimeConfig: { HTTP_AUTH_USER, HTTP_AUTH_PASS },
  } = config();

  const url = new URL(`${BACKEND_URL}${route}`);

  if (HTTP_AUTH_USER && HTTP_AUTH_PASS) {
    url.username = HTTP_AUTH_USER;
    url.password = HTTP_AUTH_PASS;
  }

  return url.toString();
};
